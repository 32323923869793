@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  display: flex;
  height: 120vh;
  background-image: url("./assets/home-bg.jpg");
  background-size: cover;
}

/*  */

.bannerImage {
  filter: brightness(97%);
}
.custom-scroll-css-apply {

}

/* ....................... */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

/* location enabled toaster custom class responsive */
@media all and (max-width: 768px) {
  .go4109123758{
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}